<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container class="py-16">
          <p class="text-h6 text-uppercase font-weight-light text-center my-16">
            EJ Partners le brinda acompañamiento en todo el marco jurídico para mantenerlo
            actualizado de la normatividad vigente.
          </p>
          <br />
          <v-row no-gutters>
            <v-col cols="12" md="6" align-self="center">
              <v-img
                max-height="800"
                src="@/assets/pexels-legal-1.jpg"
                lazy-src="pexels-legal-1.jpg"
              >
              </v-img>
            </v-col>
            <v-col cols="12" md="6" align-self="center">
              <div class="pa-lg-16 pa-md-10 px-4 py-16">
                <h2 class="text-h3 text-center font-weight-black">Asesorías Laborales</h2>
                <br />
                <p>
                  Asesoría y consultoría jurídica en aspectos laborales, civiles y
                  administrativos para empresas o independientes.
                </p>
                <ul>
                  <li>Jubilación de un trabajador</li>
                  <li>Bajas laborales</li>
                  <li>Incapacidades o invalidez</li>
                  <li>Tramitación de seguros sociales</li>
                  <li>Negociaciones de convenios laborales</li>
                  <li>
                    Gestión de las nóminas y reajuste de las políticas de contratación
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="flex-row-reverse">
            <v-col cols="12" md="6" align-self="center">
              <v-img
                max-height="800"
                src="@/assets/pexels-legal-2.jpg"
                lazy-src="pexels-legal-2.jpg"
              >
              </v-img>
            </v-col>
            <v-col cols="12" md="6" align-self="center">
              <div class="pa-lg-16 pa-md-10 px-4 py-16">
                <h2 class="text-h3 text-center font-weight-black">
                  Asesorías en Materia Familiar
                </h2>
                <br />
                <p>
                  Orientación a través de un grupo de profesionistas especializados en el
                  Derecho Familiar.
                </p>
                <ul>
                  <li>Divorcios</li>
                  <li>Testamento y herencias</li>
                  <li>Relaciones laborales</li>
                  <li>Pensiones</li>
                  <li>Procedimientos penales</li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "@/components/sections/HeroAlt";
export default {
  components: {
    HeroAlt,
  },
   mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      heroAlt: [
        {
          src: "pexels-legal-0.jpg",
          heading: " Materia Jurídica ",
        },
      ],
      cards1: [
        {
          title: "Formación Integral en RH",
          text:
            "Similique sunt in culpa qui officia deserunt mollitia animi, id est laborut dolorum fuga.harum quidem rerum facilis estexpedita distinctio.",
          icon: "mdi-material-design",
        },
        {
          title: "Administración estratégica",
          text:
            "Similique sunt in culpa qui officia deserunt mollitia animi, id est laborut dolorum fuga.harum quidem rerum facilis estexpedita distinctio.",
          icon: "mdi-desktop-mac",
        },
        {
          title: "Scrum Product Owner",
          text:
            "Similique sunt in culpa qui officia deserunt mollitia animi, id est laborut dolorum fuga.harum quidem rerum facilis estexpedita distinctio.",
          icon: "mdi-eye",
        },
      ],
    };
  },
  head() {
    return {
      title: "Servicios",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem",
        },
      ],
    };
  },
};
</script>
